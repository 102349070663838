import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {

  @Input() name: string;
  @Input() value: any;
  @Input() disabled: boolean = false;
  @Input() error: string = '';
  @Input() placeHolder: string;
  @Output() dateChanged: EventEmitter<any> = new EventEmitter();

  calendarValue: any;

  calendarState: boolean = false;

  constructor() { }

  ngOnInit() {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.value){
      this.calendarValue = this.value ? new Date(this.value) : null;
    }
  }

  onDaySelect(event){
    const formatted = moment(event).format('YYYY-MM-DD')
    this.calendarValue = new Date(formatted);

    const payload = {
      name: this.name,
      formatted
    }
    console.log(payload)
    this.dateChanged.emit(payload);
    this.changeCalendarState(false);
  }

  changeCalendarState(event){
    if(this.disabled){
      this.calendarState = false;
    }else{
      if(event != null || event != undefined){
        this.calendarState = event;
      }else{
        this.calendarState = !this.calendarState;
      }
    }
  }

}

import { Component, OnInit, OnDestroy } from "@angular/core";
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject } from "rxjs";

import {
  CampaignService,
  HelperService,
  AffiliateManagersService,
  SharedService,
  PageService,
  CommissionGroupService,
  UserService} from "@services/index";
import { IFormOptions } from '@interfaces/index';
import { IState } from "@models/index";
import * as ENUMS from "@enums/index";

@Component({
  selector: "aff-campaign-add-page",
  templateUrl: "./campaign-add-page.component.html",
  styleUrls: ["./campaign-add-page.component.scss"]
})

export class CampaignAddPage implements OnInit, OnDestroy {
  public campaignAddForm: FormGroup;
  imageURL: string = '';

  campaignTypes: any[] = [
    {
      name: 'Public',
      value: ENUMS.CampaignType.PUBLIC
    },
    {
      name: 'Public - W/A',
      value: ENUMS.CampaignType.APPROVAL
    },
    {
      name: 'Private',
      value: ENUMS.CampaignType.INVITED
    }
  ]

  tabs: any = {
    commissionsSettingTab: true,
    bannersTab: false,
    affiliatesInCampaignTab: false,
    campaignURLs: false,
  };

  datePickerOptions$: Observable<any>;
  dateOption: any = {
    active_from: '',
    active_to: ''
  }

  // enums
  status = ENUMS.Status;
  campaignType = ENUMS.CampaignType;
  commissionGroupClickApproval = ENUMS.CommissionGroupClickApproval;
  salesApproval = ENUMS.SalesApproval;
  statusType = ENUMS.StatusType;
  autoCurrency = ENUMS.AutoCurrency;
  zeroOrdersCurrency = ENUMS.FixedCostsCurrency;
  linkingMethod = ENUMS.LinkingMethod;

  errorObj: any = {};

  campaignID: string = '';
  editMode: boolean = false;
  clickSaved: Subject<boolean> = new Subject<boolean>();

  affiliateManagers: any[] = [];
  selectedAffManager: any;

  affManagersSearch = new FormControl();

  affiliateManagers$: Observable<IState>;
f
  profile$: Observable<any>;
  campaing$: Observable<any>;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private campaignService: CampaignService,
    private commissionGroupService: CommissionGroupService,
    private affiliateManagersService: AffiliateManagersService,
    private sharedService: SharedService,
    private pageService: PageService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.campaignAddForm = this.formBuilder.group({
      logo: new FormControl(null),
      name: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
      owner: new FormControl(null, [Validators.required]),
      description: new FormControl(null, [Validators.maxLength(500)]),
      status_type: new FormControl(this.statusType.MANUAL, [Validators.required]),
      status: new FormControl(this.status.ACTIVE, [Validators.required]),
      active_from: new FormControl(null, [Validators.required]),
      active_to: new FormControl(null, [Validators.required]),
      campaign_type: new FormControl(this.campaignType.PUBLIC, [Validators.required]),
      // limit_cookies_days: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      // delete_cookies: new FormControl(false, [Validators.required]),
      // previous_cookies: new FormControl(false, [Validators.required]),
      prod_id_match: new FormControl(null),
      // linking_method: new FormControl(this.linkingMethod.DEFAULT, [Validators.required]),
      commission_group: new FormControl(null)
    });

    //set breadcrum
    pageService.changePageInfo({ breadcrum: ['Campaigns', 'Add New Campaign']});

    // get datepicker options
    this.datePickerOptions$ = pageService.getDatePickerOptionsSelector();

    this.profile$ = userService.getProfileSelector();

    this.campaing$ = this.campaignService.getCampaignSelector();
    this.affiliateManagers$ = this.affiliateManagersService.getAffiliateManagersSelector();
  }

  ngOnInit() {
    // if user is manager, set as owner
    this.profile$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        if (!HelperService.isObjectEmpty(user) &&
            user.roles.length &&
            user.roles[0].role_id === ENUMS.UserRole.MANAGER) {
          this.campaignAddForm.patchValue({ owner: user.userId })
        }
      })

    // get affiliate managers
    this.affiliateManagersService.fetchAffiliateManagers(1, {});

    // get campaign id
    this.campaignID = this.route.snapshot.params.id;

    if (this.campaignID) {
      this.campaignService.fetchCampaign(this.campaignID);
    }

    this.campaing$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(campaign => {
        // check mode
        if (HelperService.isObjectEmpty(campaign)) {
          return this.editMode = false;
        }

        this.editMode = true;
        this.pageService.changePageInfo({ breadcrum: ['Campaigns', campaign.name]});

        // fill form values
        HelperService.fillFormValues(campaign, this.campaignAddForm);
        this.selectedAffManager = campaign.user;
        this.imageURL = campaign.logo_url ? campaign.logo_url : '';
        // get datepicker values
        Object.keys(this.dateOption).forEach(key => {
          campaign[key] ? this.dateOption[key] = { formatted: HelperService.formattedDateFormater(campaign[key]) }
                        : this.dateOption[key] = '';
        });
      });

      // owner search
      this.affManagersSearch.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.affiliateManagersService.fetchAffiliateManagers(1, { keyword }));

      this.affiliateManagers$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(state => {
          if (state.isLoaded) {
            this.affiliateManagers = state.data;
          }
        });
  }

  onDateChanged(event, name: string): void {
    this.campaignAddForm.patchValue({ [name] : event.formatted });
  }

  onCalendarToggle(e: Event, name: string): void {
    this.pageService.disableDate({
      name,
      date: this.dateOption[name === 'active_from' ? 'active_to' : 'active_from'].formatted
    });
  }

  onSearchOwner(options): void {
    // search value in owner
    this.affManagersSearch.patchValue(options.term);
  }

  onChangeAffManagerSelection(affManager): void {
    // set owner value in form control
    this.campaignAddForm.patchValue({ owner: affManager ? affManager.id : null });
  }

  getComSettingValues(objValues: any): void {    
    this.campaignAddForm.patchValue({ commission_group: objValues });
  }

  onChangeFormValue(options: IFormOptions): void {
    console.log(options)
    switch (options.name) {
      case "back":
        this.router.navigate(['/campaigns'])
        break;

      case "submit":
        console.log('OYOYOY')
        this.saveCampaign();
        break;

      case "logo":
        this.uploadImage(options.value);
        break;

      case "owner":
      case "status":
        this.campaignAddForm.patchValue({ [options.name]: parseInt(options.value)});
        break;

      default:
        this.campaignAddForm.patchValue({ [options.name]: options.value });
    }
  }

  uploadImage(files): void {
    this.sharedService.onLoaderToggle(true);
    const imageFile: FormData = HelperService.createFormData(files);

    this.sharedService.uploadImage(imageFile, 'campaign')
      .then(res => {
        this.imageURL = res['url'];
        this.campaignAddForm.patchValue({ logo: res['id'] });
        this.sharedService.onLoaderToggle(false);
      })
      .catch(err => {
        console.log(err);
        this.sharedService.onLoaderToggle(false);
      });
  }

  deleteImage(e: Event): void {
    this.imageURL = '';
    this.campaignAddForm.patchValue({ logo: null });
  }

  checkDisableValueValidation(): void {
    const formValues = this.campaignAddForm.value;

    if (formValues.status_type === this.statusType.MANUAL) {
      delete this.errorObj['active_from'];
      delete this.errorObj['active_to'];
    }

    // scroll on error element
    HelperService.scrollOnErrorElement(this.errorObj);
  }

  saveCampaign(): void {
    // save button click event
    this.clickSaved.next(true);

    // check validations
    this.errorObj = HelperService.checkValidation(this.campaignAddForm);
    console.log(this.errorObj)
    this.checkDisableValueValidation();

    if (HelperService.isObjectEmpty(this.errorObj)) {
      // save or update values
      if (this.editMode) {
        this.campaignService.updateCampaign({ id: parseInt(this.campaignID), ...this.campaignAddForm.value });
      } else if (!HelperService.isObjectEmpty(this.campaignAddForm.value.commission_group)) {
        this.campaignService.addCampaign(this.campaignAddForm.value);
      }
    }
  }

  onChangeTab(selectedTab: string): void {
    for(let tab in this.tabs) {
      this.tabs[tab] = false;
    }
    this.tabs[selectedTab] = true;
  }

  ngOnDestroy(): void {
    this.affiliateManagersService.clearaffiliateManager();
    this.commissionGroupService.clearCommissionGroup();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

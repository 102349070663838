import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {
  ListComponent,
  LineChartComponent,
  FilterComponent,
  ColumnChartComponent,
  CardComponent,
  BlockComponent,
  ButtonComponent,
  CheckboxComponent,
  InputComponent,
  SelectSingleComponent,
  SelectMultyComponent,
  TextAreaComponent,
  PopupComponent,
  RadioComponent,
  NotificationAlertComponent,
  CustomSelectComponent,
  ModalComponent,
  ImgPopupComponent,
  HelpQuestionMarkComponent,
} from './index';
import {AffButtonComponent} from "@shared/aff-button/aff-button.component";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzIconModule, NzInputModule, NzModalModule} from "ng-zorro-antd";
import { DndUploadComponent } from './dnd-upload/dnd-upload.component';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { TabRadioComponent } from './formGroup/tab-radio/tab-radio.component';
import { PaginationComponent } from './pagination/pagination.component';
import { BannersListHeaderComponent } from './banners-list-header/banners-list-header.component';
import { DatePickerComponent } from './formGroup/date-picker/date-picker.component';
import { SharedPipesModule } from '@pipes/shared-pipes.module';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { FormRadioComponent } from './formGroup/form-radio/form-radio.component';
import { RadioInputComponent } from './formGroup/radio-input/radio-input.component';
import { StatusSelectComponent } from './status-select/status-select.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { TableSearchComponent } from './table-search/table-search.component';

/**
 * Array of shared components
 */
const SHARED_MODULES = [
  ListComponent,
  LineChartComponent,
  AffButtonComponent,
  FilterComponent,
  ColumnChartComponent,
  CardComponent,
  BlockComponent,
  ButtonComponent,
  CheckboxComponent,
  InputComponent,
  SelectSingleComponent,
  SelectMultyComponent,
  TextAreaComponent,
  PopupComponent,
  RadioComponent,
  NotificationAlertComponent,
  CustomSelectComponent,
  ModalComponent,
  ImgPopupComponent,
  HelpQuestionMarkComponent,
  LineChartComponent,
  FilterComponent,
  ColumnChartComponent,
  CardComponent,
  BlockComponent,
  ButtonComponent,
  CheckboxComponent,
  InputComponent,
  SelectSingleComponent,
  SelectMultyComponent,
  TextAreaComponent,
  PopupComponent,
  RadioComponent,
  NotificationAlertComponent,
  CustomSelectComponent,
  ModalComponent,
  ImgPopupComponent,
  HelpQuestionMarkComponent,
  DndUploadComponent,
  TabRadioComponent,
  PaginationComponent,
  BannersListHeaderComponent,
  DatePickerComponent,
  FormRadioComponent,
  RadioInputComponent,
  StatusSelectComponent,
  ImageUploadComponent,
  TableSearchComponent
]

@NgModule({
  declarations: [
    SHARED_MODULES
  ],
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule,
    NzIconModule,
    SharedDirectivesModule,
    SharedPipesModule,
    NzDatePickerModule,
    NzInputModule,
    NzModalModule
  ],
  exports: [
    SHARED_MODULES
  ]
})

/**
 * Module for shared components
 */
export class SharedModule {
}

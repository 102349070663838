import { Component, OnInit } from '@angular/core';
import { AffManagersStatus } from '@enums/index';
import { AffiliateService, AffiliateManagersService } from '@services/index';
import { Observable, Subject } from 'rxjs';
import { IState } from '@models/index';

@Component({
  selector: 'app-manager-filter',
  templateUrl: './manager-filter.component.html',
  styleUrls: ['./manager-filter.component.scss']
})
export class ManagerFilterComponent implements OnInit {
  affiliates$: Observable<IState>;

  filterOptions = {
    status: null,
    affiliates: null,
    created_from: null,
    created_to: null
  };

  managerStatuses: any[] = [
    {name: 'Approved', value: AffManagersStatus.Approved},
    {name: 'Pending', value: AffManagersStatus.Pending},
    {name: 'Suspended', value: AffManagersStatus.Suspended},
  ];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private affiliateManagersService: AffiliateManagersService,
    private affiliateService: AffiliateService
  ) { 
    this.affiliates$ = this.affiliateService.getAffiliatesSelector();
    this.affiliateService.fetchAffiliates(1, {});
  }

  ngOnInit() {
  }

  onChangeFormValue(options) {
    this.filterOptions[options.name] = options.value;
  }

  onClearFilter() {
    this.filterOptions = {
      status: null,
      affiliates: null,
      created_from: null,
      created_to: null
    };
  }

  applyFilters(){
    this.affiliateManagersService.fetchAffiliateManagers(1, this.filterOptions);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {
  @Input() preview: any;
  @Input() name: string;
  @Input() style: any = {};
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  isVisible: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  changePicture(event){
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]); 
    reader.onload = (_event) => { 
      this.preview = reader.result; 
    }
    const payload = {
      value:  event.target.files,
      name: this.name
    }
    this.onChange.emit(payload);
  }

  removeImage(){
    this.preview = null;
    this.onDelete.emit(true);
    this.isVisible = false;
  }

  handleCancel() {

  }

  handleOk() {
  
  }

}

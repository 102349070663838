import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat',
  pure: true
})
export class DateFormatPipe implements PipeTransform {

  transform(value: any, format: string = ''): any {
    if(value){
      return moment(value).format('MM/DD/YY');
    }else{
      return '';
    }
  }

}

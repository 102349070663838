import { Component, OnInit } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';

@Component({
  selector: 'aff-header-filter',
  templateUrl: './header-filter.component.html',
  styleUrls: ['./header-filter.component.scss']
})
export class HeaderFilterComponent implements OnInit {
  contentState: boolean = false;
  currentRoute = "";

  private unsubscribe$ = new Subject<void>();

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
      }

      if (event instanceof NavigationEnd) {
          this.currentRoute = event.url.split('?')[0];          
      }

      if (event instanceof NavigationError) {
          console.log(event.error);
      }
  });
  }

  ngOnInit() {
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AuthorizedPagesRoutingModule } from './authorized-pages-routing.module';
import { AuthorizedPages } from './authorized-pages.component';
import { PermissionGuard } from '@guards/index';
import { SharedModule } from '@shared/shared.module';
import {
    DashboardPageModule,
    AffiliatesPageModule,
    ReportsPageModule,
    AffiliatePaymentsPageModule,
    AffiliateManagersPageModule,
    MessagesPageModule,
    CampaignPageModule,
    BannersPageModule,
    GeneratedCommissionsModule,
    ConfigurationPageModule,
    AffiliatesInCampaignPageModule,
    TrackingPageModule
} from '@pages/index';

import {
  PagesContainer,
  WrapperContainer,
  MainContainer,
} from '@containers/index';

import {
  NavigationComponent ,
  SubNavigationComponent ,
  SidebarComponent ,
  ProfileComponent,
  HeaderComponent ,
  BreadcrumbComponent,
  NotificationDropdownComponent
} from '@components/index';
import {SharedDirectivesModule} from "@directives/shared-directives.module";

import { HeaderFilterComponent } from '../../components/header-filter/header-filter.component';
import { PromotionToolsFilterComponent } from '../../components/header-filter/promotion-tools-filter/promotion-tools-filter.component';
import { CampaignsFilterComponent } from '@components/header-filter/campaigns-filter/campaigns-filter.component';
import { AffiliateFilterComponent } from '@components/header-filter/affiliate-filter/affiliate-filter.component';
import { AffiliatesCampaignFilterComponent } from '@components/header-filter/affiliates-campaign-filter/affiliates-campaign-filter.component';
import { ManagerFilterComponent } from '@components/header-filter/manager-filter/manager-filter.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

/**
 * Basic Components List
 */
const BASIC_COMPONENTS = [
  NavigationComponent,
  SubNavigationComponent,
  ProfileComponent,
  SidebarComponent,
  HeaderComponent,
  BreadcrumbComponent,
  NotificationDropdownComponent
];

/**
 * Containers List
 */
const CONTAINERS = [
  PagesContainer,
  WrapperContainer,
  MainContainer
];

/**
 * Modules List
 */
const MODULES = [
    DashboardPageModule,
    AffiliatesPageModule,
    ReportsPageModule,
    AffiliatePaymentsPageModule,
    AffiliateManagersPageModule,
    MessagesPageModule,
    CampaignPageModule,
    BannersPageModule,
    ConfigurationPageModule,
    AffiliatesInCampaignPageModule,
    TrackingPageModule,
    GeneratedCommissionsModule,
    SharedModule,
    NgSelectModule,
    NzDatePickerModule
];

@NgModule({
    declarations: [
        CONTAINERS,
        BASIC_COMPONENTS,
        AuthorizedPages,
        HeaderFilterComponent,
        PromotionToolsFilterComponent,
        CampaignsFilterComponent,
        AffiliateFilterComponent,
        AffiliatesCampaignFilterComponent,
        ManagerFilterComponent
    ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AuthorizedPagesRoutingModule,
    MODULES,
    SharedDirectivesModule
  ],
    providers: [PermissionGuard]
})
export class AuthorizedPagesModule { }

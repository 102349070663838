import { Component, OnInit } from '@angular/core';
import { CampaignType, Status, CampaignAffiliateStatus, StatusConditions, SuspendedManager } from '@enums/index';
import { PageService, AffiliateService, AffiliateManagersService, CampaignAffiliatesService } from "@services/index";
import { Observable, Subject } from 'rxjs';
import { IState } from '@models/index';

@Component({
  selector: 'app-affiliates-campaign-filter',
  templateUrl: './affiliates-campaign-filter.component.html',
  styleUrls: ['./affiliates-campaign-filter.component.scss']
})
export class AffiliatesCampaignFilterComponent implements OnInit {
  affiliateManagers$: Observable<IState>;
  affiliates$: Observable<IState>;
  affTypes$: Observable<any[]>;

  filterOptions = {
    camp_status: null,
    camp_type: null,
    created_from: null,
    created_to: null,
    valid_from: null,
    valid_to: null,
    aff_status: null,
    aff_inv_status: null,
    affTypes: null,
    joined_from: null,
    joined_to: null,
    suspended: null,
    managers: null,
    affiliates: null
  };

  campaignStatuses: any[] = [
    {
      name: 'Active',
      value: Status.ACTIVE.toString()
    },
    {
      name: 'Paused',
      value: Status.PAUSED.toString()
    },
    {
      name: 'Stopped',
      value: Status.STOPPED.toString()
    }
  ];

  campaignTypes: any[] = [
    {
      name: 'Public',
      value: CampaignType.PUBLIC
    },
    {
      name: 'Public - W/A',
      value: CampaignType.APPROVAL
    },
    {
      name: 'Private',
      value: CampaignType.INVITED
    }
  ]

  affiliateStatuses: any[] = [
    {name: 'Approved', value: StatusConditions.APPROVED},
    {name: 'Declined', value: StatusConditions.DECLINED},
    {name: 'Pending', value: StatusConditions.PENDING},
    {name: 'Suspended', value: StatusConditions.SUSPENDED},
    {name: 'Fraud', value: StatusConditions.FRAUD},
  ];

  involvementStatuses: any = [
    {
      name: 'Involved',
      value: CampaignAffiliateStatus.INVOLVED
    },
    {
      name: 'Invited',
      value: CampaignAffiliateStatus.INVITED
    },
    {
      name: 'Pending',
      value: CampaignAffiliateStatus.PENDING
    },
    {
      name: 'Declined',
      value: CampaignAffiliateStatus.DECLINED
    },
    {
      name: 'Suspended',
      value: CampaignAffiliateStatus.SUSPENDED
    }
  ]

  yesNo: any[] = [
    {
      name: 'Yes',
      value: true
    },
    {
      name: 'No',
      value: false
    }
  ]

  managerStatus: boolean = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private affiliateManagersService: AffiliateManagersService,
    private affiliateService: AffiliateService,
    private pageService: PageService,
    private campaignAffiliatesService: CampaignAffiliatesService
  ) { 
    this.affiliateManagers$ = this.affiliateManagersService.getAffiliateManagersSelector();
    this.affTypes$ = this.pageService.getUserTypeListSelector();
    this.affiliates$ = this.affiliateService.getAffiliatesSelector();
    
    this.affiliateManagersService.fetchAffiliateManagers(1, {});
    this.pageService.fetchUserTypes();
    this.affiliateService.fetchAffiliates(1, {});
  }

  ngOnInit() {
  }

  onChangeFormValue(options) {
    console.log(options)
    switch (options.name) {

      case 'suspended':
        this.filterOptions['suspended'] = options.value ? SuspendedManager.SUSPENDED : null;
        break;

      default:
        this.filterOptions[options.name] = options.value;
    }

    console.log(this.filterOptions);
  }

  onClearFilter() {
    this.filterOptions = {
      camp_status: null,
      camp_type: null,
      created_from: null,
      created_to: null,
      valid_from: null,
      valid_to: null,
      aff_status: null,
      aff_inv_status: null,
      affTypes: null,
      joined_from: null,
      joined_to: null,
      suspended: null,
      managers: null,
      affiliates: null
    };
    this.managerStatus = false;
  }

  applyFilters(){
    this.campaignAffiliatesService.fetchAffiliatesInCampaign(1, this.filterOptions);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

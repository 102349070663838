import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasRoleDirective } from './has-role/has-role.directive';
import { ShowPasswordDirective } from './show-password/show-password.directive';
import {DropDownDirective} from "@directives/dropdown/dropdown.directive";
import { DragDropDirective } from './drag-drop/drag-drop.directive';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';

@NgModule({
  declarations: [HasRoleDirective, ShowPasswordDirective, DropDownDirective, DragDropDirective, ClickOutsideDirective],
  exports: [
    HasRoleDirective,
    ShowPasswordDirective,
    DropDownDirective,
    DragDropDirective,
    ClickOutsideDirective
  ],
  imports: [
    CommonModule
  ]
})
export class SharedDirectivesModule { }

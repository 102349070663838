import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MyDatePickerModule } from 'mydatepicker';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '@shared/shared.module';

import { SharedPipesModule } from '@pipes/index';
import { SharedDirectivesModule } from '@directives/index';
import { CampaignPageRoutingModule } from './campaign-page-routing.module';

import {
  CampaignListPage,
  CampaignAddPage,
  CampaignAddTabsComponent,
  CampaignCommissionSettingsTabComponent,
  CampaignAffiliatesCampaignTabComponent,
  AddAffiliatePopupComponent,
  CampaignBannersTabComponent,
  CampaignListFilterComponent,
  GroupSettingCookieComponent,
  GroupSettingClickComponent,
  GroupSettingSaleComponent,
  CampaignCommissionGroupListing,
  GroupSettingLeadComponent,
  ChangeAffiliatesComGroupPopupComponent,
  CampaignTabUrlsComponent } from './index';

import {NzTableModule} from 'ng-zorro-antd/table';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {
  NzCardModule,
  NzCheckboxModule,
  NzIconModule,
  NzInputModule,
  NzModalModule
} from "ng-zorro-antd";

@NgModule({
  declarations: [
    CampaignListPage,
    CampaignAddPage,
    CampaignAddTabsComponent,
    CampaignCommissionSettingsTabComponent,
    CampaignBannersTabComponent,
    CampaignAffiliatesCampaignTabComponent,
    CampaignListFilterComponent,
    AddAffiliatePopupComponent,
    CampaignCommissionGroupListing,
    GroupSettingCookieComponent,
    GroupSettingClickComponent,
    GroupSettingSaleComponent,
    GroupSettingLeadComponent,
    ChangeAffiliatesComGroupPopupComponent,
    CampaignTabUrlsComponent
  ],
  imports: [
    CommonModule,
    CampaignPageRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    SharedModule,
    NgSelectModule,
    SharedPipesModule,
    MyDatePickerModule,
    SharedDirectivesModule,
    NzTableModule,
    NzButtonModule,
    NzCardModule,
    NzIconModule,
    NzInputModule,
    NzCheckboxModule,
    NzModalModule,
  ]
})
export class CampaignPageModule { }

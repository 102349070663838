import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from './find-value/findValueInArray.pipe';
import { SafePipe } from './safe/safe.pipe';
import { DateFormatPipe } from './date-format/date-format.pipe';
import { CampaignStatusPipe } from './campaign-status/campaign-status.pipe';

@NgModule({
  declarations: [FilterPipe, SafePipe, DateFormatPipe, CampaignStatusPipe],
  exports: [
    FilterPipe,
    SafePipe,
    DateFormatPipe,
    CampaignStatusPipe
  ],
  imports: [
    CommonModule
  ]
})
export class SharedPipesModule { }

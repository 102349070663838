import { Component, OnInit, OnDestroy, ViewChild} from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';

import { IModalvalue } from '@shared-models/index';
import { AffiliateService, PageService, HelperService } from "@services/index";
import { IState } from "@models/index";
import { IPage } from '@interfaces/index';
import { NzTableComponent } from "ng-zorro-antd";

@Component({
  selector: 'aff-affiliates-list-page',
  templateUrl: './affiliates-list-page.component.html',
  styleUrls: ['./affiliates-list-page.component.scss']
})
export class AffiliatesListPage implements OnInit, OnDestroy {
  affiliateID = [];
  affiliateNames = [];
  deleteConfirmation: boolean = false;

  rows = [];
  loadingIndicator: boolean = true;
  selectedRows = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  filterOptions = {
    keyword: '',
  };

  affiliate$: Observable<IState>;
  private unsubscribe$ = new Subject<void>();

  setOfCheckedId = new Set<number>();
  checked = false;
  indeterminate = false;

  statusColors: any = {
    approved: '#08AC16',
    suspended: '#687A9E',
    involved: '#79CC74',
    invited: '#2F80ED',
    pending: '#F19100',
    fraud: '#6D0000',
    declined: '#ED5757'
  }

  @ViewChild('basicTable', { static: false}) basicTable: NzTableComponent
  constructor(private router: Router,
    private affiliateService: AffiliateService, 
    private pageService: PageService
    ) {
      pageService.changePageInfo({ breadcrum: ['Affiliates']});

      this.affiliate$ = this.affiliateService.getAffiliatesSelector();
    }

  ngOnInit() {
    this.affiliateService.fetchAffiliates(1, {});
    // get affiliate list
    this.affiliate$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) 
        this.rows = [];
        for (const affiliate of state.data) {
            const row = { 
              id: affiliate.id,
              name: affiliate.profile.first_name + ' ' + affiliate.profile.last_name,
              type: affiliate.affType ? affiliate.affType.name : '',
              manager: affiliate.manager,
              status: affiliate.status ? affiliate.status.name : '',
              dateJoined: HelperService.dateFormater(affiliate.created_at),
              parentAffiliate: affiliate.parent ? affiliate.parent.email : '',
              websiteUrl: affiliate.profile.site_url,
            };
            this.rows.push(row);
        }
        this.rows = [...this.rows];
        // get pagination options
        this.page.currentPage = state.currentPage;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
    });
  }

  onSearch(event){
    this.affiliateService.fetchAffiliates(1, {keyword: event});
  }

  onAllChecked(checked: boolean): void {
    this.basicTable.data
      .forEach(({id}) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }

  }

  refreshCheckedStatus(): void {
    this.checked = this.basicTable.data.every(({id}) =>
      this.setOfCheckedId.has(id)
    );
    this.indeterminate =
      this.basicTable.data.some(({id}) => this.setOfCheckedId.has(id)) &&
      !this.checked;
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  nextPage(e) {
    console.log(e);
    this.page.currentPage = e + 1
    this.selectedRows = [];
    this.affiliateService.fetchAffiliates(this.page.currentPage, {});
  }

  prevPage(e) {
    console.log(e);
    this.selectedRows = [];
    this.page.currentPage = e - 1
    this.affiliateService.fetchAffiliates(this.page.currentPage, {});
  }

  onAddAffiliate() {
    this.router.navigate(['/affiliates/affiliate'])
  }

  onDeleteAffiliate() {
    console.log(this.selectedRows, this.setOfCheckedId)
    if(this.setOfCheckedId.size) {
      this.deleteConfirmation = true; 
      this.affiliateID = Array.from(this.setOfCheckedId);

      // for (const selectedRow of this.selectedRows) {
      //   this.affiliateNames.push(selectedRow.name);
      // }
    }
    
    this.selectedRows = [];
  }

  confirmationOption(options: IModalvalue){
    if (options.confirm) {
      this.affiliateID.forEach(affID=> {
       this.affiliateService.deleteAffiliate(affID);   
      }); 
    }

    this.affiliateNames = [];
    this.affiliateID = [];
    this.deleteConfirmation = false;
  }


  onAction(action: string, row: any) {
    switch (action) {
      case "delete":
        this.deleteConfirmation = true;
        this.affiliateNames.push(row.name);
        this.affiliateID.push(row.id);
        break;

      case "edit":
        this.router.navigate([`/affiliates/affiliate/${row.id}`])
        break;

      default:
      // default
    }
  }

  setPage(e): void {
    this.selectedRows = [];
    this.affiliateService.fetchAffiliates(e.offset + 1, {});
  }
  
  onSelect(e): void {
    this.selectedRows = e.selected;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

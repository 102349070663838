import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'campaignStatus',
  pure: true
})
export class CampaignStatusPipe implements PipeTransform {

  transform(value: any): any {
    switch(value){
      case('MANUAL'): {
        return `
          <img src="assets/images/icons/manual.svg" alt="">
          Manual
        `;
        break;
      }
      case('STOPPED'): {
        return `
          <img src="assets/images/icons/stopped.svg" alt="">
          Stopped
        `;
        break;
      }
      case('PAUSED'): {
        return `
          <img src="assets/images/icons/paused.svg" alt="">
          Paused
        `;
        break;
      }
      case('ACTIVE'): {
        return `
          <img src="assets/images/icons/active.svg" alt="">
          Active
        `;
        break;
      }
    }
    //<img src="assets/images/icons/edit.svg" alt="">
    return null;
  }

}

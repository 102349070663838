import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {Observable, Subject} from "rxjs";
import {BannerService, PageService} from "@services/index";
import {IPage} from "@interfaces/index";
import {takeUntil} from "rxjs/operators";
import {NzTableComponent} from "ng-zorro-antd";

@Component({
  selector: "aff-banners-list-page",
  templateUrl: "./banners-list-page.component.html",
  styleUrls: ["./banners-list-page.component.scss"],
})
export class BannersListPage implements OnInit, OnDestroy {
  bannerID = [];
  deleteConfirmation: boolean = false;
  deleteValues: string = "";
  banner$: Observable<any>;
  unsubscribe$ = new Subject<void>();
  checked = false
  indeterminate = false;
  showDeleteModal = false
  showFiterComponent: boolean = false;
  loadingIndicator: boolean = true;
  selectedRows = [];
  selectedPromotionToolName: string
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0,
  };
  setOfCheckedId = new Set<number>();

  rows: any[] = [];
  @ViewChild('basicTable', { static: false}) basicTable: NzTableComponent

  constructor(
    private router: Router,
    private bannerService: BannerService,
    private pageService: PageService
  ) {
    this.banner$ = bannerService.getBannersSelector();
    pageService.changePageInfo({breadcrum: ["Promotion Tools"]});
  }

  onAllChecked(checked: boolean): void {
    this.basicTable.data
      .forEach(({id}) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }

  }

  refreshCheckedStatus(): void {
    this.checked = this.basicTable.data.every(({id}) =>
      this.setOfCheckedId.has(id)
    );
    this.indeterminate =
      this.basicTable.data.some(({id}) => this.setOfCheckedId.has(id)) &&
      !this.checked;
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  ngOnInit() {
    this.bannerService.fetchBanners(1, {});
    this.banner$.pipe(takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state.isLoaded) {
        this.rows = [];
        for (const banner of state.data) {
          const row = {
            id: banner.id,
            promotionToolName: banner.name,
            promotionToolType: banner.type,
            destinationUrl: banner.destination_url
              ? banner.destination_url.url
              : "",
            campaign: banner.campaign ? banner.campaign.name : "",
            affiliateManager: banner.campaign
              ? banner.campaign.user
                ? banner.campaign.user.email
                : ""
              : "",
          };
          this.rows.push(row);
        }
      }

      this.rows = [...this.rows];
      // get pagination options
      this.page.currentPage = state.currentPage;
      this.page.perPage = state.perPage;
      this.page.maxPages = state.maxPages;
      this.page.total = state.total;
      // close loader
      this.loadingIndicator = false;
    });
  }

  onSearch(event){
    this.bannerService.fetchBanners(1, {keyword: event});
  }

  onAddBanner() {
    this.router.navigate(["/promotion-tools/promotion-tool"]);
  }

  nextPage(e) {
    console.log(e);
    this.page.currentPage = e + 1
    this.selectedRows = [];
    this.bannerService.fetchBanners(this.page.currentPage, {});
  }

  prevPage(e) {
    console.log(e);
    this.selectedRows = [];
    this.page.currentPage = e - 1
    this.bannerService.fetchBanners(this.page.currentPage, {});
  }

  onDeleteBanner() {
    if (this.selectedRows.length) {
      this.deleteConfirmation = true;
      for (const selectedRow of this.selectedRows) {
        this.bannerID.push(selectedRow.id);
      }
    }
    this.deleteValues = "";
    this.selectedRows = [];
  }

  confirmationOption() {
    this.bannerService.deleteBanner(this.selectedPromotionToolName)
    this.showDeleteModal = false
  }

  onSelect(e) {
    this.selectedRows = e.selected;
  }

  onToggleFilter() {
    this.showFiterComponent = !this.showFiterComponent;
  }

  onAction(action: string, row: any) {
    switch (action) {
      case "delete":
        this.showDeleteModal = true;
        this.deleteValues = `Promotion Tool: <br><br> ${row.promotionToolName}`;
        this.bannerID.push(row.id);
        break;

      case "edit":
        this.router.navigate([`/promotion-tools/promotion-tool/${row.id}`]);
        break;

      default:
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  showRemovalModal(name: string) {
    this.showDeleteModal = true
    this.selectedPromotionToolName = name
  }

  // deleteSelectedItems() {
  //   const arr = []
  //   this.setOfCheckedId.forEach(item => {
  //     arr.push(item)
  //   })
  //
  //   this.bannerService.removePromotionTools(arr).subscribe(() => {
  //     this.bannerService.fetchBanners(1, {});
  //   })
  // }
}


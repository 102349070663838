import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-table-search',
  templateUrl: './table-search.component.html',
  styleUrls: ['./table-search.component.scss']
})
export class TableSearchComponent implements OnInit {
  @Input() value: string;
  @Input() placeHolder: string = 'Search';
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  private searchSubject: Subject<string> = new Subject();
  private unsubscribe$ = new Subject<any>();

  constructor() { }

  ngOnInit() {
    this.searchSubject
    .pipe(
      distinctUntilChanged(),
      debounceTime(500),
    ).subscribe(resp => {
      let payload = {
        name: 'keyword',
        value: resp
      };
  
      this.onChange.emit(payload);
    });
  }

  onTextChange(value: string): void {
    this.searchSubject.next(value);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }
}

import { Component, OnInit } from '@angular/core';
import { CampaignType, Status, ManagerStatus, FixedCostsCurrency } from '@enums/index';
import { CampaignService, UserService, AffiliateManagersService } from "@services/index";
import { Observable, Subject } from 'rxjs';
import { IState } from '@models/index';


@Component({
  selector: 'app-campaigns-filter',
  templateUrl: './campaigns-filter.component.html',
  styleUrls: ['./campaigns-filter.component.scss']
})
export class CampaignsFilterComponent implements OnInit {
  affiliateManagers$: Observable<IState>;

  filterOptions = {
    status: null,
    camType: null,
    manager_status: null,
    created_from: '',
    created_to: '',
    valid_from: '',
    valid_to: '',
    commissionType: null,
    sale_commission_from: '',
    sale_commission_to: '',
    lead_commission_from: '',
    lead_commission_to: '',
    managers: null,
    archived: false
  };

  campaignStatuses: any[] = [
    {
      name: 'Active',
      value: Status.ACTIVE.toString()
    },
    {
      name: 'Paused',
      value: Status.PAUSED.toString()
    },
    {
      name: 'Stopped',
      value: Status.STOPPED.toString()
    }
  ]

  campaignTypes: any[] = [
    {
      name: 'Public',
      value: CampaignType.PUBLIC
    },
    {
      name: 'Public - W/A',
      value: CampaignType.APPROVAL
    },
    {
      name: 'Private',
      value: CampaignType.INVITED
    }
  ]

  yesNo: any[] = [
    {
      name: 'Yes',
      value: true
    },
    {
      name: 'No',
      value: false
    }
  ]

  commisionTypes: any[] = [
    {
      name: '$ - Dollar',
      value: FixedCostsCurrency.MONEY
    },
    {
      name: '% - Percentage',
      value: FixedCostsCurrency.PERCENT
    }
  ]
  managerStatus: boolean = false;

  private unsubscribe$ = new Subject<void>();

  constructor(private affiliateManagersService: AffiliateManagersService, private userService: UserService, private campaingService: CampaignService) {
    this.affiliateManagers$ = this.affiliateManagersService.getAffiliateManagersSelector();
    this.affiliateManagersService.fetchAffiliateManagers(1, {});
  }

  ngOnInit() {
  }

  onClearFilter() {
    this.filterOptions = {
      status: null,
      camType: null,
      manager_status: null,
      created_from: '',
      created_to: '',
      valid_from: '',
      valid_to: '',
      commissionType: null,
      sale_commission_from: '',
      sale_commission_to: '',
      lead_commission_from: '',
      lead_commission_to: '',
      managers: null,
      archived: false
    };
    this.managerStatus = false;
  }

  applyFilters(){
    this.campaingService.fetchCampaigns(1, this.filterOptions);
  }

  onChangeFormValue(options) {
    console.log(options)
    switch (options.name) {

      case 'manager_status':
        this.filterOptions['manager_status'] = options.value ? ManagerStatus.SUSPENDED : null;
        break;

      default:
        this.filterOptions[options.name] = options.value;
    }

    console.log(this.filterOptions);
  }

  ngOnDestroy(): void {
    this.userService.clearSelectedFilter();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-banner-list-item-delete-modal',
  templateUrl: './banner-list-item-delete-modal.component.html',
  styleUrls: ['./banner-list-item-delete-modal.component.scss']
})
export class BannerListItemDeleteModalComponent implements OnInit {
  @Output() negative = new EventEmitter()
  @Output() positive = new EventEmitter()
  @Input() isVisible: boolean = false;
  @Input() promotionToolName: string;

  constructor() {
  }

  ngOnInit() {
  }

  handleCancel() {
    this.negative.emit()
  }

  handleOk() {
    this.positive.emit()
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CampaignAffiliateStatus } from '@enums/index';

@Component({
  selector: 'app-status-select',
  templateUrl: './status-select.component.html',
  styleUrls: ['./status-select.component.scss']
})
export class StatusSelectComponent implements OnInit {
  statuses: any = [
    {
      name: 'Involved',
      value: CampaignAffiliateStatus.INVOLVED
    },
    {
      name: 'Invited',
      value: CampaignAffiliateStatus.INVITED
    },
    {
      name: 'Pending',
      value: CampaignAffiliateStatus.PENDING
    },
    {
      name: 'Declined',
      value: CampaignAffiliateStatus.DECLINED
    },
    {
      name: 'Suspended',
      value: CampaignAffiliateStatus.SUSPENDED
    }
  ]

  colors: any = {
    [CampaignAffiliateStatus.INVOLVED]: '#289E20',
    [CampaignAffiliateStatus.INVITED]: '#2F80ED',
    [CampaignAffiliateStatus.PENDING]: '#FFAF02',
    [CampaignAffiliateStatus.DECLINED]: '#D3220B',
    [CampaignAffiliateStatus.SUSPENDED]: '#687A9E',
  }
  listState: boolean = false;

  @Input() selectedStatus: string = this.statuses[0].value;
  @Input() table: boolean = false;
  @Output() onChange: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  selectStatus(status){
    this.selectedStatus = status.value;
    this.listState = false;
    this.onChange.emit(status.value);
  }

}

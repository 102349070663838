import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-radio',
  templateUrl: './form-radio.component.html',
  styleUrls: ['./form-radio.component.scss']
})
export class FormRadioComponent implements OnInit {
  @Input() label: string;
  @Input() tabs: {name: string, title: string, value: string}[] = [];
  @Input() selectedTab: string;
  @Output() onTabChange: EventEmitter<any> = new EventEmitter();
  
  constructor() { }

  ngOnInit() {
    if(!this.selectedTab && this.tabs.length){
      this.onTabChange.emit(this.tabs[0]);
    }
  }

  selectTab(tab){
    this.onTabChange.emit(tab);
  }

}

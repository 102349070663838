import { Component, OnInit } from '@angular/core';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import * as ENUMS from '@enums/index';
import {
  AffiliateService,
  AffiliateManagersService,
  UserService,
  HelperService,
  PageService,
  PaymentMethodsService
} from '@services/index'
import { IState } from "@models/index";
import { IFormOptions } from '@interfaces/index';
import { IModalvalue } from '@shared-models/index';

@Component({
  selector: 'app-affiliate-filter',
  templateUrl: './affiliate-filter.component.html',
  styleUrls: ['./affiliate-filter.component.scss']
})
export class AffiliateFilterComponent implements OnInit {

  affiliates$: Observable<IState>;
  paymentMethods$: Observable<IState>;
  affTypes$: Observable<any[]>;

  filterOptions = {
    status: null,
    paymentMethods: null,
    affTypes: null,
    created_from: '',
    created_to: '',
    parent_affiliates: null
  };

  affiliateStatuses: any[] = [
    {name: 'Approved', value: ENUMS.StatusConditions.APPROVED},
    {name: 'Declined', value: ENUMS.StatusConditions.DECLINED},
    {name: 'Pending', value: ENUMS.StatusConditions.PENDING},
    {name: 'Suspended', value: ENUMS.StatusConditions.SUSPENDED},
    {name: 'Fraud', value: ENUMS.StatusConditions.FRAUD},
  ];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private affiliateService: AffiliateService,
    private affiliateManagersService: AffiliateManagersService,
    private userService: UserService,
    private pageService: PageService,
    private paymentMethodsService: PaymentMethodsService
  ) { 
    this.affTypes$ = this.pageService.getUserTypeListSelector();
    this.affiliates$ = this.affiliateService.getAffiliatesSelector();
    this.paymentMethods$ = this.paymentMethodsService.getPaymentMethodsSelector();
    
    this.pageService.fetchUserTypes();
    this.affiliateService.fetchAffiliates(1, {});
    this.paymentMethodsService.fetchPaymentMethods(1, {});
  }

  ngOnInit() {
  }

  onChangeFormValue(options) {
    switch (options.name) {
      default:
        this.filterOptions[options.name] = options.value;
    }
  }

  onClearFilter(){
    this.filterOptions = {
      status: null,
      paymentMethods: null,
      affTypes: null,
      created_from: '',
      created_to: '',
      parent_affiliates: null
    };
    this.affiliateService.fetchAffiliates(1, this.filterOptions);
  }
  applyFilters(){
    this.affiliateService.fetchAffiliates(1, this.filterOptions);
  }

  ngOnDestroy(): void {
    this.userService.clearSelectedFilter();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

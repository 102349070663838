import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-dnd-upload',
  templateUrl: './dnd-upload.component.html',
  styleUrls: ['./dnd-upload.component.scss']
})
export class DndUploadComponent implements OnInit {
  @Input() name: string;
  @Input() label: string = 'Drag and drop a File';
  @Input() file: any;
  @Input() style: any = {};
  @Output() onFileSelect: EventEmitter<any> = new EventEmitter();
  @Output() onFileDelete: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onFileDrop(event){
    if(!event) return;
    const payload = {
      name: this.name,
      value: [event]
    }
    this.onFileSelect.emit(payload);
  }
  
  onFileRemove(){
    this.onFileDelete.emit(true);
  }

  justLog(evt){
    console.log(evt)
  }
}
